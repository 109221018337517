<template>
	<div
		class="data-list-container main-list-view">
		<vs-navbar class="main-toolbar" type="flat" color="#4690DE">
			<div slot="title">
				<vs-navbar-title>
					<div class="logo-top-left">
						<router-link to="/">
							<img src="@/assets/images/logo/koblatil.png" alt="Kobla" class="pt-3 pl-3"/>
						</router-link>
					</div>
				</vs-navbar-title>
			</div>
			<vs-navbar-item v-if="!userProfile" class="pl-10">
				<div class="text-register">
					<router-link to="/register">{{ $t('Register') }}</router-link>
				</div>
			</vs-navbar-item>
			<vs-navbar-item  v-if="!userProfile" class="pl-4">
				<vs-button @click="login" color="#4690DE" class="mr-4 btn-login-upcoming">{{$t('Login')}}</vs-button>
			</vs-navbar-item>
		</vs-navbar>
	<vs-row vs-justify="center" class="mt-12 mb-base">
		<vs-col type="flex" vs-justify="center" vs-align="center" class="ml-2 mr-2 xs:w-1/1 sm:w-3/4 lg:w-1/3">
		<div class="container">
			<div class="mt-10 mb-5">
				<h3 class="mb-10">WE HAVE THE UTMOST RESPECT FOR YOUR PRIVACY</h3>
				<h1>Privacy Policy</h1>
			</div>

			<div>
				Your privacy is important to us. It is Koblatil's policy to respect your privacy regarding any information we may collect from you across our website, https://Koblatil.no/, and other sites we own and operate.
				<br><br>
				We only ask for personal information when we truly need it to provide a service to you. We collect it by fair and lawful means, with your knowledge and consent. We also let you know why we’re collecting it and how it will be used.
				<br><br>
				We only retain collected information for as long as necessary to provide you with your requested service. What data we store, we’ll protect within commercially acceptable means to prevent loss and theft, as well as unauthorized access, disclosure, copying, use or modification.
				<br><br>
				We don’t share any personally identifying information publicly or with third-parties, except when required to by law.
				<br><br>
				Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and practices of these sites, and cannot accept responsibility or liability for their respective privacy policies.
				<br><br>
				You are free to refuse our request for your personal information, with the understanding that we may be unable to provide you with some of your desired services.
				<br><br>
				Your continued use of our website will be regarded as acceptance of our practices around privacy and personal information. If you have any questions about how we handle user data and personal information, feel free to contact us.
			</div>

			<div class="mt-12">
				<h5 class="mb-3">Log Files</h5>
				Koblatil follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.
			</div>

			<div class="mt-12">
				<h5 class="mb-3">Cookies and Web Beacons</h5>
				Like any other website, Koblatil uses 'cookies'. These cookies are used to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by customizing our web page content based on visitors' browser type and/or other information.
			</div>
		</div>
		</vs-col>
	</vs-row>
	</div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      isLoggingIn: false,
    };
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
      user: 'user',
    }),
    userProfile() {
      const profile = this.user;
      return profile;
    },
  },
  methods: {
    checkLogin() {
      // If user is already logged in notify
      // if (this.$store.state.auth.isUserLoggedIn()) {
      if (this.$store.state.isLoggedIn) {
        // Close animation if passed as payload
        // this.$vs.loading.close()

        this.$vs.notify({
          title: 'Login Attempt',
          text: 'You are already logged in!',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
        });

        return false;
      }
      return true;
    },
    login() {
      this.$router.push('/login').catch(() => {});
    },
  },
};
</script>
<style lang="scss" scoped>
.event-user{
	margin-left: auto;
	margin-right: auto;
		max-width: 681px;
}
.main-toolbar{
	background-color: #F8F8F8!important;
}
.logo-top-left{
	width: 174.18px;
	height: 65.75px;
}
.text-register{
	font-size: 18px!important;
	color: #4690DE;
}
</style>
